<template>
  <div class="wrapper">
    <h4>修改用户信息</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="用户ID" prop="userId">
            <el-input v-model="form.userId" auto-complete="off" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="登录用户名" prop="userLoginName">
            <el-input v-model="form.userLoginName" auto-complete="off" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="form.realName" placeholder="" auto-complete="off" @input="change($event)"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="sex">
            <el-input v-model="form.sex" placeholder="" auto-complete="off" @input="change($event)"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-show="showUserType">
        <el-col :span="12">
          <el-form-item label="用户类型" prop="userType">
            <el-select v-model="form.userType" placeholder="请选择用户类型" style="width: 100%" @change="change($event)">
              <el-option v-for="item in userTypeList" :key="item.typeCode" :label="item.typeName"
                         :value="item.typeCode"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="图像" prop="userImgs">
            <upload-img
                :limit="1"
                :upload-lists="imgList"
                :before-upload="beforeAvatarUpload"
                @uploadChange="uploadSuccess1"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="16">
          <el-form-item label="所属区域" prop="tenant">
            <region-form
                ref="regionForm"
                v-model="form.tenant"
                :disable-value="disableRegion"
                :placeholder="tenantNames"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="抬头" prop="relateEnvId">
            <el-input v-model="form.relateEnvId" placeholder="请选择抬头" auto-complete="off" :disabled="true">
              <el-button slot="append" icon="el-icon-search" @click="selectPersonId()"/>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主页抬头" prop="mainTitle">
            <el-input v-model="form.mainTitle" placeholder="主页抬头" auto-complete="off" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="扩展字段1" prop="col1">
            <el-input v-model="form.col1" auto-complete="off" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="扩展字段2" prop="col2">
            <el-input v-model="form.col2" auto-complete="off" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="扩展字段3" prop="col3">
            <el-input v-model="form.col3" auto-complete="off" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="扩展字段4" prop="col4">
            <el-input v-model="form.col4" auto-complete="off" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择人员" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <vxe-form
            title-align="right"
            title-width="120"
            title-colon
            :data="searchData"
            @submit="search"
            @reset="searchReset"
        >
          <vxe-form-item title="数据分区Id" span="12">
            <vxe-input v-model="searchData.id" placeholder="请输入数据分区Id" clearable/>
          </vxe-form-item>
          <vxe-form-item title="主页面标题" span="12">
            <vxe-input v-model="searchData.mainPageTitle" placeholder="请输入主页面标题" clearable/>
          </vxe-form-item>
          <vxe-form-item align="center" span="12">
            <vxe-button type="submit" status="my-orange" icon="fa fa-check">搜索</vxe-button>
            <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
          </vxe-form-item>
        </vxe-form>
        <div style="margin-top:10px;">
          <el-table ref="singleTable" :data="tableData" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                    v-model="radio"
                    :label="scope.$index"
                    style="color: #fff;padding-left: 10px; "
                    @change.native="getCurrentRow(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="id" width="100" label="数据分区Id" align="center"/>
            <el-table-column prop="mainPageTitle" label="主页标题" width="100" align="center"/>
            <el-table-column prop="col1" label="扩展字段1" width="100" align="center"/>
            <el-table-column prop="col2" label="扩展字段2" width="100" align="center"/>
            <el-table-column prop="col3" label="扩展字段3" width="100" align="center"/>
            <el-table-column prop="col4" label="扩展字段4" width="100" align="center"/>
            <el-table-column prop="col5" label="扩展字段5" align="center"/>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
                background
                :current-page="tablePage.currentPage"
                :page-sizes="[50, 100, 500]"
                :page-size="tablePage.pageSize"
                layout="total,jumper,prev, pager, next,sizes"
                :total="tablePage.totalResult"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'

export default {
  name: 'EditUser',
  components: {
    UploadImg,
    RegionForm
  },
  data() {
    return {
      radio: '',
      height: 240,
      tableData: [],
      tenantNames: '',
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      visible: false,
      searchData: {
        id: '',
        mainPageTitle: ''
      },
      form: {},
      showUserType: false,
      userTypeList: [],
      imgList: [],
      rules: {
        realName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['downloadFile', 'getGlobalEvn', 'getUserTypesServe', 'editUserInfo']),
    ...mapGetters([
      'userInfos'
    ]),
    getCurrentRow(row) {
      this.form.personId = row.id
      this.form.tel = row.tel
      this.form.idNum = row.idNum
      this.form.sex = row.sex
      this.form.name = row.name
      this.form.relateEnvId = row.id
      this.form.mainTitle = row.mainPageTitle
      this.form.col1 = row.col1
      this.form.col2 = row.col2
      this.form.col3 = row.col3
      this.form.col4 = row.col4
      this.form.userType = row.type
    },
    cancel() {
      this.visible = false
    },
    search() {
      this.getConsConsultant()
    },
    change(e) {
      this.$forceUpdate()
    },
    handleSizeChange: function (size) {
      this.tablePage.pageSize = size
      this.getConsConsultant()
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 6
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过5MB!')
        return
      }
    },
    // 控制页面的切换
    handleCurrentChange: function (currentPage) {
      this.tablePage.currentPage = currentPage
      this.getConsConsultant()
    },
    getConsConsultant() {
      const params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          id: this.searchData.id,
          mainPageTitle: this.searchData.mainPageTitle
        }
      }
      this.getGlobalEvn(params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    initView() {
      const itemData = JSON.parse(this.$route.query.item)
      console.log(itemData)
      this.showUserType = itemData.type.toString().substring(0, 1) === '2'
      this.getUserTypeLists()
      this.tenantNames = itemData.tenantNames
      this.form.userId = itemData.id
      this.form.userType = itemData.type
      this.form.userLoginName = itemData.loginName
      this.form.realName = itemData.descrption
      this.form.sex = itemData.sex
      this.form.userImgs = itemData.photoId
      this.form.relateEnvId = itemData.relateEnvId
      this.form.mainTitle = itemData.mainPageTitle
      this.form.col1 = itemData.col1
      this.form.col2 = itemData.col2
      this.form.col3 = itemData.col3
      this.form.col4 = itemData.col4
      this.form.tenant = itemData.tenant.split(',').slice(2)
      this.$nextTick(() => {
        // this.$refs.regionForm.initRegion()
      })
      const userInfo = this.userInfos()
      this.disableRegion = userInfo.tenant

      if (itemData.photoId) {
        this.getFileDetail1(itemData.photoId)
      }

      this.getConsConsultant()
    },
    getUserTypeLists() {
      this.getUserTypesServe({
        type: 2
      }).then(res => {
        if (res.code == 200) {
          this.userTypeList = res.data
          this.$forceUpdate()
        }
      })
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      console.log(ids)
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.imgList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.imgList = data.join(',')
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form
          }

          if (this.disableRegion.split(',').length > 2) {
            params.tenant = (typeof this.form.tenant === 'undefined' || this.form.tenant == '') ? this.disableRegion : `${this.disableRegion},${this.form.tenant.join(',')}`
          } else {
            params.tenant = (typeof this.form.tenant === 'undefined' || this.form.tenant == '') ? '1' : `1,101,${this.form.tenant.join(',')}`
          }
          if (this.form.imgList) {
            params.userImgs = this.form.imgList
          }
          const server = 'editUserInfo'
          console.log(params)
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '添加成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    selectPersonId() {
      this.visible = true
    },
    searchReset() {
      this.searchData = {}
      this.getConsConsultant()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
